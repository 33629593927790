import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import React from 'react'
import NextImage from 'next/image'

const image = '/img/splash-logo.svg'

const Splash = () => (
    <Grid container justify='center'>
        <div className='vertical-center'>
            <NextImage 
                src={image}
                width={164}
                height={183}
                layout="responsive"
                objectFit="contain"
                alt='Remitbee Logo' />
            <LinearProgress style={{ width: 150 }} />
        </div>

        <style>{`.vertical-center{margin:0;position:absolute;top:50%;-ms-transform:translateY(-50%);transform:translateY(-50%)}`}</style>
    </Grid>

)

export default Splash;